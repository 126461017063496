import React, { useCallback, useEffect, useState, useRef } from 'react';
import { FaUser, FaTrash, FaLanguage, FaSignOutAlt, FaShoppingBag, FaClipboardList, FaMotorcycle } from 'react-icons/fa';
import { motion, AnimatePresence } from 'framer-motion';
import LoginForm from './components/LoginForm';
import OrderPage from './components/OrderPage';
import MenuManager from './components/MenuManager';

const FastFoodPlatform = () => {
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const [menu, setMenu] = useState([]);
  const [orders, setOrders] = useState([]);
  const [categories, setCategories] = useState(['Все', 'Горячие блюда', 'Закуски', 'Напитки']);
  const [activeCategory, setActiveCategory] = useState('Все');
  const [isOrderPage, setIsOrderPage] = useState(false);
  const [cart, setCart] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [isOrdersVisible, setIsOrdersVisible] = useState(false);
  const [currentOrder,] = useState(null);

  const userMenuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (userMenuRef.current && !userMenuRef.current.contains(event.target)) {
        setIsUserMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const fetchUserData = useCallback((userId) => {
    fetch(`https://1.iqtest.pp.ua:3002/users/${userId}`)
      .then(response => response.json())
      .then(data => {
        if (data.user) {
          setIsLoggedIn(true);
          setCurrentUser(data.user);
          fetchMenuAndCategories(data.user.id);
          fetchOrders(data.user.id);
        }
      })
      .catch(error => console.error('Ошибка при получении данных пользователя:', error));
  }, []);
  
  useEffect(() => {
    const userId = localStorage.getItem('userId');
    if (userId) {
      fetchUserData(userId);
    }
  }, [fetchUserData]);

  useEffect(() => {
    if (currentUser && currentUser.id) {
      console.log('Запрос заказов для пользователя:', currentUser.id);
      fetch(`https://1.iqtest.pp.ua:3002/orders?user_id=${currentUser.id}`)
        .then(response => response.json())
        .then(data => {
          console.log('Полученные данные заказов:', data);
          setOrders(data.orders);
        })
        .catch(error => console.error('Ошибка при получении заказов:', error));
    }
  }, [currentUser]);

  const fetchMenuAndCategories = async (userId) => {
    try {
      const response = await fetch(`https://1.iqtest.pp.ua:3002/menu?user_id=${userId}`);
      const data = await response.json();
      setMenu(data.menu || []);
      setCategories(data.categories || ['Все', 'Горячие блюда', 'Закуски', 'Напитки']);
    } catch (error) {
      console.error('Ошибка при получении меню и категорий:', error);
    }
  };

  const fetchOrders = useCallback(() => {
    if (currentUser && currentUser.id) {
      console.log('Запрос заказов для пользователя:', currentUser.id);
      fetch(`https://1.iqtest.pp.ua:3002/orders?user_id=${currentUser.id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          return response.json();
        })
        .then(data => {
          console.log('Полученные данные заказов:', data);
          setOrders(data.orders);
        })
        .catch(error => {
          console.error('Ошибка при получении заказов:', error.message);
        });
    }
  }, [currentUser]);
  
  useEffect(() => {
    fetchOrders();
  }, [fetchOrders]);

  const handleLogin = (username, password) => {
    fetch('https://1.iqtest.pp.ua:3002/login', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ username, password })
    })
    .then(response => response.json())
    .then(data => {
      if (data.error) {
        alert('Неверное имя пользователя или пароль');
      } else {
        setIsLoggedIn(true);
        setCurrentUser(data.user);
        localStorage.setItem('userId', data.user.id);
        fetchMenuAndCategories(data.user.id);
        fetchOrders(data.user.id);
      }
    })
    .catch(error => console.error('Ошибка при входе:', error));
  };

  const handleRegister = (username, password) => {
    fetch('https://1.iqtest.pp.ua:3002/register', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ username, password })
    })
    .then(response => response.json())
    .then(data => {
      if (data.error) {
        alert('Ошибка при регистрации: ' + data.error);
      } else {
        alert('Регистрация успешна! Теперь вы можете войти.');
        handleLogin(username, password);
      }
    })
    .catch(error => console.error('Ошибка при регистрации:', error));
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    setCurrentUser(null);
    setMenu([]);
    setOrders([]);
    setCart([]); // Очищаем корзину при выходе
    setIsUserMenuOpen(false);
    localStorage.removeItem('userId');
  };

  const handleDeleteUser = () => {
    if (window.confirm('Вы уверены, что хотите удалить свой аккаунт? Это действие нельзя отменить.')) {
      fetch(`https://1.iqtest.pp.ua:3002/users/${currentUser.id}`, {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' }
      })
      .then(async response => {
        if (!response.ok) {
          const errorData = await response.json().catch(() => ({}));
          throw new Error(`HTTP error! status: ${response.status}, message: ${errorData.error || 'Unknown error'}`);
        }
        return response.json();
      })
      .then(data => {
        if (data.success) {
          alert('Пользователь успешно удален');
          handleLogout();
        } else {
          alert('Ошибка при удалении пользователя: ' + (data.error || 'Неизвестная ошибка'));
        }
      })
      .catch(error => {
        console.error('Ошибка при удалении пользователя:', error);
        alert(`Произошла ошибка при удалении пользователя: ${error.message}`);
      });
    }
  };

  const handleUserClick = () => {
    setIsUserMenuOpen(!isUserMenuOpen);
  };

  const handleChangeLanguage = () => {
    alert('Функция смены языка в разработке');
  };

  const addToCart = (item) => {
    setCart([...cart, item]);
  };

  // Функция для размещения заказа
  const placeOrder = (orderData) => {
    console.log('Отправляемые данные заказа:', orderData);
  
    // Проверка данных заказа
    if (!orderData.user_id || !Array.isArray(orderData.items) || orderData.items.length === 0 || !orderData.total_price) {
      alert('Ошибка: неполные данные заказа');
      return;
    }
  
    // Дополнительная проверка для каждого элемента
    orderData.items.forEach(item => {
      if (!item.menu_item_id || item.quantity <= 0 || !item.price) {
        console.error('Ошибка: некорректный элемент заказа:', item);
        alert('Ошибка: один или несколько товаров некорректны');
        return;
      }
    });
  
    fetch('https://1.iqtest.pp.ua:3002/orders', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(orderData)
    })
      .then(response => {
        if (!response.ok) {
          return response.text().then(text => {
            throw new Error(`HTTP error! status: ${response.status}, message: ${text}`);
          });
        }
        return response.json();
      })
      .then(data => {
        if (data.success) {
          // Добавляем новый заказ в список заказов
          setOrders(prevOrders => [...prevOrders, { ...orderData, id: data.orderId }]);
  
          // Обновляем список заказов с сервера, чтобы отобразить новый заказ
          fetchOrders();
  
          alert('Заказ успешно оформлен!');
        } else {
          alert('Ошибка при оформлении заказа: ' + (data.error || 'Неизвестная ошибка'));
        }
      })
      .catch(error => {
        console.error('Ошибка при размещении заказа:', error.message);
        alert('Произошла ошибка при оформлении заказа. Пожалуйста, попробуйте снова.');
      });
  };
  

  const updateOrderStatus = (orderId, newStatus) => {
    fetch(`https://1.iqtest.pp.ua:3002/orders/${orderId}`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ status: newStatus })
    })
    .then(response => response.json())
    .then(data => {
      if (data.success) {
        fetchOrders(); // Обновляем список заказов после изменения статуса
      } else {
        console.error('Ошибка при обновлении статуса заказа:', data.error);
      }
    })
    .catch(error => console.error('Ошибка при обновлении статуса заказа:', error));
  };

  const removeOrder = (orderId) => {
    if (window.confirm('Вы уверены, что хотите удалить этот заказ?')) {
      fetch(`https://1.iqtest.pp.ua:3002/orders/${orderId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(response => {
        if (!response.ok) {
          return response.text().then(text => {
            throw new Error(`HTTP error! status: ${response.status}, message: ${text}`);
          });
        }
        return response.json();
      })
      .then(data => {
        if (data.success) {
          setOrders(orders.filter(order => order.id !== orderId));
          alert('Заказ успешно удален');
        } else {
          alert('Ошибка при удалении заказа: ' + (data.error || 'Неизвестная ошибка'));
        }
      })
      .catch(error => {
        console.error('Ошибка при удалении заказа:', error.message);
        alert('Произошла ошибка при удалении заказа. Пожалуйста, попробуйте снова.');
      });
    }
  };

  const handleGlovoClick = () => {
    alert('Функция подключения к Glovo находится в разработке');
  };

  if (!isLoggedIn) {
    return <LoginForm onLogin={handleLogin} onRegister={handleRegister} />;
  }

  if (isOrderPage) {
    return (
      <OrderPage
        categories={categories}
        setActiveCategory={setActiveCategory}
        activeCategory={activeCategory}
        filteredMenu={menu.filter(item => activeCategory === 'Все' || item.category === activeCategory)}
        addToCart={addToCart}
        cart={cart}
        placeOrder={placeOrder}
        currentOrder={currentOrder}
        setIsOrderPage={setIsOrderPage}
        currentUser={currentUser}
      />
    );
  }

  return (
    <div className="min-h-screen p-8 bg-gray-900 text-white">
      <div className="container mx-auto">
        <header className="flex justify-between items-center mb-8">
          <h1 className="text-4xl font-bold">FastFood Меню</h1>
          <div className="flex items-center">
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="bg-blue-500 text-white px-4 py-2 rounded-full hover:bg-blue-600 transition flex items-center"
              onClick={handleUserClick}
            >
              <FaUser className="mr-2" /> Профиль
            </motion.button>
          </div>
        </header>
        {isUserMenuOpen && (
          <motion.div
            ref={userMenuRef}
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ type: "spring", stiffness: 260, damping: 20 }}
            className="absolute right-20 top-20 bg-white rounded-lg shadow-xl p-4 w-64 z-50"
          >
            <button onClick={() => setIsOrderPage(true)} className="flex items-center text-gray-700 hover:text-blue-500 mb-4 w-full">
              <FaShoppingBag className="mr-2" /> На стороне пользователя
            </button>
            <button onClick={() => setIsOrdersVisible(!isOrdersVisible)} className="flex items-center text-gray-700 hover:text-blue-500 mb-4 w-full">
              <FaClipboardList className="mr-2" /> Заказы
            </button>
            <button onClick={handleChangeLanguage} className="flex items-center text-gray-700 hover:text-blue-500 mb-4 w-full">
              <FaLanguage className="mr-2" /> Сменить язык
            </button>
            <button onClick={handleGlovoClick} className="flex items-center text-gray-700 hover:text-blue-500 mb-4 w-full">
              <FaMotorcycle className="mr-2" /> Подключиться к Glovo
            </button>
            <button onClick={handleLogout} className="flex items-center text-gray-700 hover:text-blue-500 mb-4 w-full">
              <FaSignOutAlt className="mr-2" /> Выйти
            </button>
            <hr className="my-2 border-gray-300" />
            <button
              onClick={handleDeleteUser}
              className="flex items-center text-red-500 hover:text-red-700 w-full"
            >
              <FaTrash className="mr-2" /> Удалить пользователя
            </button>
          </motion.div>
        )}

        {isOrdersVisible && (
          <div className="mb-8 bg-gray-800 rounded-lg shadow-lg p-4">
            <h2 className="text-2xl font-bold mb-4">Заказы</h2>
            <AnimatePresence>
              {orders.length > 0 ? (
                <motion.div className="space-y-4">
                  {orders.map((order) => (
                    <motion.div
                      key={order.id}
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: -20 }}
                      transition={{ duration: 0.3 }}
                      className="bg-gray-700 rounded-lg p-4 shadow-md"
                    >
                      <div className="flex justify-between items-center mb-2">
                        <h3 className="text-lg font-semibold">Заказ #{order.id}</h3>
                        <span className="text-sm text-gray-400">{new Date(order.order_date).toLocaleString()}</span>
                      </div>
                      <ul className="space-y-2">
                      {order.items.map((item, index) => (
  <li key={index} className="flex items-center space-x-2">
{item.image ? (
  <img src={item.image} alt={item.name} className="w-12 h-12 object-cover rounded-md" />
) : (

      <div className="w-12 h-12 bg-gray-500 rounded-md flex items-center justify-center text-xs">Нет фото</div>
    )}
    <div className="flex-grow">
      <h4 className="font-semibold">{item.name || 'Название отсутствует'}</h4>
      <div className="flex justify-between items-center">
        <span>{item.quantity} x {item.price} ₽</span>
        <span className="font-semibold">{(item.quantity * item.price).toFixed(2)} ₽</span>
      </div>
    </div>
  </li>
))}
                      </ul>
                      <div className="mt-4 pt-2 border-t border-gray-600 flex justify-between items-center">
                        <span className="font-semibold">Итого:</span>
                        <span className="text-lg font-bold">{order.total_price} ₽</span>
                      </div>
                      <div className="mt-2 flex justify-between items-center">
                        <span className="text-sm text-gray-400">
                          Статус: <span className="font-semibold">{order.status}</span>
                        </span>
                        <select
                          value={order.status}
                          onChange={(e) => updateOrderStatus(order.id, e.target.value)}
                          className="bg-gray-600 text-white px-2 py-1 rounded"
                        >
                          <option value="Новый">Новый</option>
                          <option value="Готовится">Готовится</option>
                          <option value="В пути">В пути</option>
                          <option value="Доставлен">Доставлен</option>
                        </select>
                      </div>
                      <div className="mt-2 flex justify-end">
                        <button
                          onClick={() => removeOrder(order.id)}
                          className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-600 transition"
                        >
                          Удалить заказ
                        </button>
                      </div>
                    </motion.div>
                  ))}
                </motion.div>
              ) : (
                <motion.p
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                >
                  У вас пока нет заказов.
                </motion.p>
              )}
            </AnimatePresence>
          </div>
        )}

        {currentUser && (
          <MenuManager
            categories={categories || []}
            setCategories={setCategories}
            activeCategory={activeCategory || 'Все'}
            setActiveCategory={setActiveCategory}
            menu={menu || []}
            setMenu={setMenu}
            currentUser={currentUser}
          />
        )}
      </div>
    </div>
  );
};

export default FastFoodPlatform;
