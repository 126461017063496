import React, { useState } from 'react';
import { FaEdit, FaSave, FaUpload, FaPlus, FaTimes, FaTrash } from 'react-icons/fa';
import { motion } from 'framer-motion';

const MenuManager = ({ 
  categories, 
  setCategories, 
  activeCategory, 
  setActiveCategory, 
  menu, 
  setMenu, 
  currentUser 
}) => {
  const [editingCategories, setEditingCategories] = useState(false);
  const [newCategory, setNewCategory] = useState('');
  const [editingCategoryName, setEditingCategoryName] = useState('');
  const [editingItem, setEditingItem] = useState(null);

  const toggleCategoryEdit = () => {
    setEditingCategories(!editingCategories);
    setEditingCategoryName('');
  };

  const addCategory = () => {
    if (newCategory && !categories.includes(newCategory)) {
      const updatedCategories = [...categories, newCategory];
      updateCategories(updatedCategories);
    }
  };

  const removeCategory = (category) => {
    if (category !== 'Все') {
      const updatedCategories = categories.filter(cat => cat !== category);
      updateCategories(updatedCategories);
    }
  };

  const updateCategories = (updatedCategories) => {
    fetch(`https://1.iqtest.pp.ua:3002/categories/${currentUser.id}`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ categories: updatedCategories })
    })
    .then(response => response.json())
    .then(data => {
      if (data.success) {
        setCategories(updatedCategories);
        setNewCategory('');
        if (activeCategory !== 'Все' && !updatedCategories.includes(activeCategory)) {
          setActiveCategory('Все');
        }
      }
    })
    .catch(error => console.error('Ошибка при обновлении категорий:', error));
  };

  const editCategoryName = (oldName, newName) => {
    if (newName && !categories.includes(newName)) {
      const updatedCategories = categories.map(cat => cat === oldName ? newName : cat);
      updateCategories(updatedCategories);
      setMenu(menu.map(item => item.category === oldName ? {...item, category: newName} : item));
      if (activeCategory === oldName) {
        setActiveCategory(newName);
      }
    }
    setEditingCategoryName('');
  };

  const addToMenu = () => {
    const newItem = {
      name: 'Новое блюдо',
      category: activeCategory || 'Другое',
      price: 0,
      description: 'Описание нового блюда',
      image: 'https://images.pexels.com/photos/1640777/pexels-photo-1640777.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
      user_id: currentUser.id
    };
    
    fetch('https://1.iqtest.pp.ua:3002/menu', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(newItem)
    })
    .then(response => response.json())
    .then(data => {
      if (data.success) {
        newItem.id = data.id;
        setMenu([...menu, newItem]);
      }
    })
    .catch(error => console.error('Ошибка при добавлении элемента меню:', error));
  };

  const editMenuItem = (id) => {
    const itemToEdit = menu.find(item => item.id === id);
    setEditingItem({ ...itemToEdit });
  };

  const handleEditChange = (field, value) => {
    setEditingItem({ ...editingItem, [field]: value });
  };

  const saveEditedItem = () => {
    fetch(`https://1.iqtest.pp.ua:3002/menu/${editingItem.id}`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(editingItem)
    })
    .then(response => response.json())
    .then(data => {
      if (data.success) {
        setMenu(menu.map(item => item.id === editingItem.id ? editingItem : item));
        setEditingItem(null);
      }
    })
    .catch(error => console.error('Ошибка при обновлении элемента меню:', error));
  };

  const removeFromMenu = (id) => {
    fetch(`https://1.iqtest.pp.ua:3002/menu/${id}`, {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
    })
    .then(response => {
      if (response.ok) {
        setMenu(menu.filter(item => item.id !== id));
      } else {
        console.error('Ошибка при удалении блюда:', response);
      }
    })
    .catch(error => console.error('Ошибка при удалении блюда:', error));
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setEditingItem({ ...editingItem, image: reader.result });
      };
      reader.readAsDataURL(file);
    }
  };

  const filteredMenu = activeCategory === 'Все' ? menu : menu.filter(item => item.category === activeCategory);

  return (
    <>
      <div className="flex flex-wrap -mx-4 mb-8 items-center relative">
        {categories.map(category => (
          <div key={category} className="mx-2 my-1 flex items-center">
            <button
              onClick={() => setActiveCategory(category)}
              className={`px-4 py-2 rounded-full ${activeCategory === category ? 'bg-blue-500 text-white' : 'bg-gray-700 text-gray-200'}`}
            >
              {editingCategories && category !== 'Все' && editingCategoryName === category ? (
                <input
                  type="text"
                  value={editingCategoryName}
                  onChange={(e) => setEditingCategoryName(e.target.value)}
                  onBlur={() => editCategoryName(category, editingCategoryName)}
                  className="bg-transparent border-b border-gray-500 focus:outline-none"
                />
              ) : (
                category
              )}
            </button>
            {editingCategories && category !== 'Все' && (
              <div className="ml-2 flex items-center">
                <button
                  onClick={() => setEditingCategoryName(category)}
                  className="text-blue-500 hover:text-blue-600 transition mr-2"
                >
                  <FaEdit />
                </button>
                <button
                  onClick={() => removeCategory(category)}
                  className="text-red-500 hover:text-red-600 transition"
                >
                  <FaTrash />
                </button>
              </div>
            )}
          </div>
        ))}
        {editingCategories && (
          <div className="flex items-center mx-2 my-1">
            <input
              type="text"
              value={newCategory}
              onChange={(e) => setNewCategory(e.target.value)}
              placeholder="Новая категория"
              className="px-4 py-2 rounded-l-full border-r-0 focus:outline-none bg-gray-700 text-white"
            />
            <button
              onClick={addCategory}
              className="px-4 py-2 rounded-r-full bg-green-500 text-white hover:bg-green-600 transition"
            >
              <FaPlus />
            </button>
          </div>
        )}
        <button
          onClick={toggleCategoryEdit}
          className="mx-2 my-1 p-2 rounded-full bg-gray-700 text-gray-200 hover:bg-gray-600 transition"
        >
          {editingCategories ? <FaTimes /> : <FaEdit />}
        </button>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {filteredMenu.map(item => (
          <motion.div
            key={item.id}
            className="bg-gray-800 rounded-lg shadow-lg overflow-hidden"
            whileHover={{ scale: 1.05 }}
            transition={{ type: 'spring', stiffness: 300 }}
          >
            {editingItem && editingItem.id === item.id ? (
              <div className="p-4">
                <div className="relative mb-4">
                  <img
                    src={editingItem.image}
                    alt={editingItem.name}
                    className="w-full h-48 object-cover rounded"
                  />
                  <label htmlFor={`imageUpload-${item.id}`} className="absolute bottom-2 right-2 bg-blue-500 text-white p-2 rounded-full cursor-pointer hover:bg-blue-600 transition">
                    <FaUpload />
                  </label>
                  <input
                    id={`imageUpload-${item.id}`}
                    type="file"
                    accept="image/*"
                    onChange={handleImageUpload}
                    className="hidden"
                  />
                </div>
                <input
                  type="text"
                  value={editingItem.name}
                  onChange={(e) => handleEditChange('name', e.target.value)}
                  className="w-full mb-2 p-2 border rounded bg-gray-700 text-white"
                />
                <textarea
                  value={editingItem.description}
                  onChange={(e) => handleEditChange('description', e.target.value)}
                  className="w-full mb-2 p-2 border rounded bg-gray-700 text-white"
                />
                <input
                  type="number"
                  value={editingItem.price}
                  onChange={(e) => handleEditChange('price', Number(e.target.value))}
                  className="w-full mb-2 p-2 border rounded bg-gray-700 text-white"
                />
                <select
                  value={editingItem.category}
                  onChange={(e) => handleEditChange('category', e.target.value)}
                  className="w-full mb-2 p-2 border rounded bg-gray-700 text-white"
                >
                  {categories.filter(cat => cat !== 'Все').map(category => (
                    <option key={category} value={category}>{category}</option>
                  ))}
                </select>
                <button
                  onClick={saveEditedItem}
                  className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition"
                >
                  <FaSave /> Сохранить
                </button>
              </div>
            ) : (
              <>
                <img
                  src={item.image}
                  alt={item.name}
                  className="w-full h-48 object-cover"
                />
                <div className="p-4">
                  <h2 className="text-xl font-semibold mb-2">{item.name}</h2>
                  <p className="text-gray-300 mb-2">{item.description}</p>
                  <div className="flex justify-between items-center mb-4">
                    <span className="text-lg font-bold">{item.price} ₴</span>
                    <span className="text-sm text-gray-400">{item.category}</span>
                  </div>
                  <div className="flex justify-end items-center">
                    <button
                      onClick={() => removeFromMenu(item.id)}
                      className="text-red-500 hover:text-red-600 transition mr-2"
                      aria-label={`Удалить ${item.name} из меню`}
                    >
                      <FaTrash />
                    </button>
                    <button
                      onClick={() => editMenuItem(item.id)}
                      className="text-gray-500 hover:text-gray-600 transition"
                      aria-label={`Редактировать ${item.name}`}
                    >
                      <FaEdit />
                    </button>
                  </div>
                </div>
              </>
            )}
          </motion.div>
        ))}
      </div>

      <button
        onClick={addToMenu}
        className="mt-8 bg-green-500 text-white px-6 py-3 rounded-full hover:bg-green-600 transition"
      >
        Добавить новое блюдо
      </button>
    </>
  );
};

export default MenuManager;