import React, { useState, useEffect, useRef } from 'react';
import { FaShoppingCart, FaPlus, FaMinus, FaTrash, FaShoppingBag } from 'react-icons/fa';
import { motion, AnimatePresence } from 'framer-motion';

const OrderPage = ({ 
  categories, 
  setActiveCategory, 
  activeCategory, 
  filteredMenu, 
  setIsOrderPage,
  placeOrder,
  currentUser 
}) => {
  const [cart, setCart] = useState([]);
  const [isCartOpen, setIsCartOpen] = useState(false);
  const [addedItem, setAddedItem] = useState(null);
  const cartRef = useRef(null);

  const addToCart = (item) => {
    const existingItem = cart.find((cartItem) => cartItem.id === item.id);
    if (existingItem) {
      setCart(
        cart.map((cartItem) =>
          cartItem.id === item.id ? { ...cartItem, quantity: cartItem.quantity + 1 } : cartItem
        )
      );
    } else {
      setCart([...cart, { ...item, quantity: 1 }]);
    }
    setAddedItem(item);
    setTimeout(() => setAddedItem(null), 1000);
  };

  const removeFromCart = (itemId) => {
    setCart(cart.filter((item) => item.id !== itemId));
  };

  const updateQuantity = (itemId, delta) => {
    setCart(
      cart.map((item) => {
        if (item.id === itemId) {
          const newQuantity = Math.max(1, item.quantity + delta);
          return { ...item, quantity: newQuantity };
        }
        return item;
      })
    );
  };

  const totalPrice = cart.reduce((sum, item) => sum + item.price * item.quantity, 0);

  const handlePlaceOrder = async () => {
    if (cart.length > 0 && currentUser && currentUser.id) {
      const order = {
        user_id: currentUser.id,
        items: cart.map(item => ({
          menu_item_id: item.id,
          quantity: item.quantity,
          price: item.price
        })),
        total_price: totalPrice,
        status: 'Новый'
      };
      placeOrder(order);  // Отправка заказа на сервер
      setCart([]);  // Очистка корзины
      setIsCartOpen(false);  // Закрытие корзины
    } else if (!currentUser || !currentUser.id) {
      alert('Пожалуйста, войдите в систему, чтобы оформить заказ.');
    } else {
      alert('Корзина пуста. Добавьте товары перед оформлением заказа.');
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (cartRef.current && !cartRef.current.contains(event.target)) {
        setIsCartOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="min-h-screen p-8 bg-gray-900 text-white">
      <div className="container mx-auto">
        
        {/* Контейнер для статичной кнопки "Вернуться к редактированию" */}
        <div className="mb-8 z-50 bg-transparent p-4">
          <button
            className="bg-blue-500 text-white px-4 py-2 rounded-full hover:bg-blue-600 transition flex items-center"
            onClick={() => setIsOrderPage(false)}
          >
            <FaShoppingBag className="mr-2" /> Вернуться к редактированию
          </button>
        </div>

        {/* Корзина всегда в верхнем правом углу */}
        <div
          className={`p-4`}
          style={{
            position: cart.length > 0 ? 'fixed' : 'absolute', // Корзина всегда в одном месте, но меняется поведение
            top: '20px', // Всегда вверху
            right: '20px', // Всегда справа
            zIndex: 50,
          }}
        >
          {currentUser ? (
            <span className="mr-4"></span> 
          ) : (
            <span className="mr-4">Пользователь не авторизован</span>
          )}
          <motion.button
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            className="text-white rounded-full p-2 shadow-lg relative"
            onClick={() => setIsCartOpen(!isCartOpen)}
          >
            <FaShoppingCart size={24} />
            {cart.length > 0 && (
              <span className="absolute -top-2 -right-2 bg-red-500 text-white rounded-full w-6 h-6 flex items-center justify-center text-xs">
                {cart.reduce((sum, item) => sum + item.quantity, 0)}
              </span>
            )}
          </motion.button>
        </div>

        <div className="flex flex-wrap -mx-4 mb-8 items-center relative">
          {categories.map((category) => (
            <button
              key={category}
              onClick={() => setActiveCategory(category)}
              className={`mx-2 my-1 px-4 py-2 rounded-full ${
                activeCategory === category
                  ? 'bg-blue-500 text-white'
                  : 'bg-gray-700 text-gray-200'
              }`}
            >
              {category}
            </button>
          ))}
        </div>

        <AnimatePresence>
          {isCartOpen && (
            <motion.div
              ref={cartRef}
              initial={{ opacity: 0, y: '100%' }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: '100%' }}
              transition={{ type: 'spring', damping: 25, stiffness: 500 }}
              className="fixed left-0 bottom-0 w-full h-3/4 bg-gray-800 shadow-2xl rounded-t-3xl p-6 overflow-y-auto z-50"
            >
              <h2 className="text-2xl font-bold mb-4 text-white">Корзина</h2>
              {cart.length === 0 ? (
                <p className="text-gray-300">Ваша корзина пуста</p>
              ) : (
                <>
                  {cart.map((item) => (
                    <motion.div
                      key={item.id}
                      layout
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      className="flex items-center justify-between mb-4 bg-gray-700 p-4 rounded-lg"
                    >
                      <img src={item.image} alt={item.name} className="w-20 h-20 object-cover rounded-md" />
                      <div className="flex-grow ml-4">
                        <h3 className="font-semibold text-white">{item.name}</h3>
                        <p className="text-gray-300">{item.price} ₽</p>
                      </div>
                      <div className="flex items-center">
                        <button
                          onClick={() => updateQuantity(item.id, -1)}
                          className="text-blue-500 hover:text-blue-600 p-1"
                          disabled={item.quantity === 1}
                        >
                          <FaMinus />
                        </button>
                        <span className="mx-2 text-white">{item.quantity}</span>
                        <button
                          onClick={() => updateQuantity(item.id, 1)}
                          className="text-blue-500 hover:text-blue-600 p-1"
                        >
                          <FaPlus />
                        </button>
                      </div>
                      <button
                        onClick={() => removeFromCart(item.id)}
                        className="text-red-500 hover:text-red-600 ml-4"
                      >
                        <FaTrash />
                      </button>
                    </motion.div>
                  ))}
                  <div className="mt-4 pt-4 border-t border-gray-600">
                    <p className="text-xl font-bold text-white">Итого: {totalPrice} ₽</p>
                    <button 
                      className="mt-4 bg-blue-500 text-white py-3 px-6 rounded-full w-full hover:bg-blue-600 transition-colors text-lg font-semibold"
                      onClick={handlePlaceOrder}
                    >
                      Оформить заказ
                    </button>
                  </div>
                </>
              )}
            </motion.div>
          )}
        </AnimatePresence>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {filteredMenu.map((item) => (
            <motion.div
              key={item.id}
              whileHover={{ scale: 1.03 }}
              className="bg-gray-800 rounded-xl shadow-lg overflow-hidden relative"
            >
              <img
                src={item.image}
                alt={item.name}
                className="w-full h-48 object-cover"
              />
              <div className="p-4">
                <h2 className="text-xl font-semibold mb-2 text-white">{item.name}</h2>
                <p className="text-gray-300 mb-4">{item.price} ₽</p>
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  className="bg-blue-500 text-white py-2 px-4 rounded-full w-full text-lg font-semibold hover:bg-blue-600 transition-colors"
                  onClick={() => addToCart(item)}
                >
                  Добавить в корзину
                </motion.button>
              </div>
              <AnimatePresence>
                {addedItem && addedItem.id === item.id && (
                  <motion.div
                    initial={{ opacity: 0, y: 50 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -50, scale: 0.5 }}
                    transition={{ duration: 0.5, ease: 'easeInOut' }}
                    className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50"
                  >
                    <motion.div
                      initial={{ scale: 0 }}
                      animate={{ scale: [0, 1.2, 1] }}
                      transition={{ times: [0, 0.5, 1], duration: 0.5 }}
                      className="bg-white p-4 rounded-lg shadow-lg"
                    >
                      <p className="text-green-500 font-semibold">Добавлено в корзину!</p>
                    </motion.div>
                  </motion.div>
                )}
              </AnimatePresence>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default OrderPage;
