import React, { useState } from 'react';
import { FaSignInAlt, FaUserPlus, FaChevronDown, FaChevronUp } from 'react-icons/fa';

const LoginForm = ({ onLogin, onRegister }) => {
  const [loginData, setLoginData] = useState({ username: '', password: '' });
  const [registerData, setRegisterData] = useState({ username: '', password: '', confirmPassword: '' });
  const [showRegister, setShowRegister] = useState(false);
  const [error, setError] = useState('');

  const handleLogin = async (e) => {
    e.preventDefault();
    setError('');
    try {
      await onLogin(loginData.username, loginData.password);
    } catch (err) {
      setError(err.message || 'Ошибка при входе');
    }
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    setError('');
    if (registerData.password !== registerData.confirmPassword) {
      setError('Пароли не совпадают');
      return;
    }
    try {
      await onRegister(registerData.username, registerData.password);
    } catch (err) {
      setError(err.message || 'Ошибка при регистрации');
    }
  };

  const handleInputChange = (e, formType) => {
    const { name, value } = e.target;
    if (formType === 'login') {
      setLoginData({ ...loginData, [name]: value });
    } else {
      setRegisterData({ ...registerData, [name]: value });
    }
  };

  return (
    <div className="min-h-screen p-8 bg-gray-900 text-white">
      <div className="container mx-auto max-w-md">
        <h1 className="text-4xl font-bold mb-8 text-center">FastFood Platform</h1>
        <div className="bg-gray-800 p-6 rounded-lg shadow-lg">
          {error && <p className="text-red-500 mb-4">{error}</p>}
          {!showRegister ? (
            <>
              <h2 className="text-2xl font-semibold mb-4">Вход</h2>
              <form onSubmit={handleLogin}>
                <input
                  type="text"
                  name="username"
                  placeholder="Имя пользователя"
                  className="w-full bg-gray-700 text-white px-4 py-2 rounded mb-2"
                  value={loginData.username}
                  onChange={(e) => handleInputChange(e, 'login')}
                  required
                />
                <input
                  type="password"
                  name="password"
                  placeholder="Пароль"
                  className="w-full bg-gray-700 text-white px-4 py-2 rounded mb-4"
                  value={loginData.password}
                  onChange={(e) => handleInputChange(e, 'login')}
                  required
                />
                <button
                  type="submit"
                  className="w-full bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition flex items-center justify-center"
                >
                  <FaSignInAlt className="mr-2" /> Войти
                </button>
              </form>
            </>
          ) : (
            <>
              <h2 className="text-2xl font-semibold mb-4">Регистрация</h2>
              <form onSubmit={handleRegister}>
                <input
                  type="text"
                  name="username"
                  placeholder="Имя пользователя"
                  className="w-full bg-gray-700 text-white px-4 py-2 rounded mb-2"
                  value={registerData.username}
                  onChange={(e) => handleInputChange(e, 'register')}
                  required
                />
                <input
                  type="password"
                  name="password"
                  placeholder="Пароль"
                  className="w-full bg-gray-700 text-white px-4 py-2 rounded mb-2"
                  value={registerData.password}
                  onChange={(e) => handleInputChange(e, 'register')}
                  required
                />
                <input
                  type="password"
                  name="confirmPassword"
                  placeholder="Подтвердите пароль"
                  className="w-full bg-gray-700 text-white px-4 py-2 rounded mb-4"
                  value={registerData.confirmPassword}
                  onChange={(e) => handleInputChange(e, 'register')}
                  required
                />
                <button
                  type="submit"
                  className="w-full bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition flex items-center justify-center"
                >
                  <FaUserPlus className="mr-2" /> Зарегистрироваться
                </button>
              </form>
            </>
          )}
          <button
            className="w-full mt-4 bg-gray-700 text-white px-4 py-2 rounded hover:bg-gray-600 transition flex items-center justify-center"
            onClick={() => {
              setShowRegister(!showRegister);
              setError('');
            }}
          >
            {showRegister ? <FaChevronUp className="mr-2" /> : <FaChevronDown className="mr-2" />}
            {showRegister ? 'Вернуться ко входу' : 'Регистрация'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;